/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyCaFlBPXuFB76gGlb3pMBQrfKRWpJ7-XLk",
  "appId": "1:371920722157:web:4f9f15ce609a1dd0c9d4e4",
  "authDomain": "schooldog-i-jasper-ga.firebaseapp.com",
  "measurementId": "G-5C8X84X9B9",
  "messagingSenderId": "371920722157",
  "project": "schooldog-i-jasper-ga",
  "projectId": "schooldog-i-jasper-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-jasper-ga.appspot.com"
}
